<template>
  <div class="nk-tb-item" :class="{'nk-tb-head': isHeading}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TableRow",
  props: {
    isHeading: Boolean,
  }
}
</script>
