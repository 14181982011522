<template>
  <div class="nk-tb-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SpecialTable",
}
</script>
