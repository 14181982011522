<template>
  <li class="nk-tb-action-hidden">
    <router-link v-if="to" :to="to" class="btn btn-trigger btn-icon">
      <nio-icon :icon="icon" :class="'text-'+iconColor"></nio-icon>
    </router-link>
    <a v-else href="javascript:;" class="btn btn-trigger btn-icon">
      <nio-icon :icon="icon" :class="'text-'+iconColor"></nio-icon>
    </a>
  </li>
</template>

<script>
import NioIcon from "@core/components/nio-icon/NioIcon";
export default {
  name: "TableAction",
  components: {NioIcon},
  props: {
    icon: {
      required: true,
      type: String,
    },
    iconColor: String,
    to: {
      type: Object,
    },
  }
}
</script>
