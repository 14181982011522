<template>
  <div class="nk-tb-col" :class="classesList">
    <slot></slot>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "TableCol",
  props: {
    breakPoint: {
      type: String,
      default: '',
    },
    hasCheckBox: Boolean,
  },
  setup(props){

    let classesList = ref([])
    if(props.breakPoint.length){
      classesList.value.push(`tb-col-${props.breakPoint}`)
    }
    if(props.hasCheckBox){
      classesList.value.push('nk-tb-col-check')
    }

    return {
      classesList,
    }

  }
}
</script>
